@media only screen and (max-width: 800px) {
    .desktop {
        display: none !important;
    }

    .niceLink {
        width: 90%;
        margin: 10px auto;
        font-weight: bold;
        color: #000269;
    }

    div#adminLink {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background-color: #443636;
        padding: 10px;
        color: white;
    }

    .errorArea {
        border: 1px solid black;
        width: 80%;
        margin: 15px auto;
        padding: 15px;
        box-sizing: border-box;
        background-color: #ec9696;
        border-radius: 10px;
    }

    .errorArea ul {
        list-style: initial;
        padding-right: 24px;
    }

    .errorArea li {
        padding: 10px 0;
    }

    h1 {
        color: #000269;
        font-weight: bold;
        font-size: 23px;
        margin-right: 25px;
    }

    header, #warper {
        width: 100%;
        margin: 0 auto;
    }

    #logo {
        float: none;
        width: 88%;
        box-sizing: border-box;
        padding: 0 13px 0;
        margin: 25px auto;
    }

    img {
        max-width: 100%;
    }

    #logo img {
        width: 100%;
    }

    #siteLinks {
        width: 100%;
        float: none;
        box-sizing: border-box;
        background-color: #f1f0e9;
    }

    #siteLinksList li {
        display: inline-block;
        padding: 4px;
        text-align: center;
        cursor: pointer;
        vertical-align: middle;
    }

    #siteLinksList li img {
        margin-bottom: 10px;
    }

    ul#siteLinksList {
        text-align: left;
    }

    #headerMainAd img {
        width: 100%;
    }

    #siteMenu {
        text-align: center;
        display: none;
    }

    #siteMenu li {
        display: block;
        padding: 7px;
        /* border-radius: 50%; */
        text-align: center;
        color: black;
        background-color: #ff7200;
        margin: 9px;
        /* width: 80px; */
        height: 60px;
        vertical-align: middle;
        position: relative;
    }

    .innerMenu {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        background-color: #fffdf0;
        border: 1px solid #ff7200;
        width: 80%;
        margin: 0 auto;
    }

    .iMenu:hover {
        background-color: #ff7200;

    }

    .iMenu.selected {
        background-color: #ff7200;
        color: #fff;
    }

    .iMenu {
        padding: 5px 0;

    }

    #headerAds {
        text-align: center;
    }

    #headerAds li:last-child {
        margin: 10px 0 10px 0;
    }

    #headerAds li {
        display: inline-block;
        margin: 10px 0 10px 1.5em;
    }

    #sideAds img {
        width: 100%;
    }

    #sideAds {
        display: none;
    }

    #contentWithoutFrame {
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 50px;
        padding: 25px;
        box-sizing: border-box;
    }

    #contentWithoutFrame #content {
        width: 100%;
    }

    #content {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fffef8+0,e1edef+100 */
        background: #fffef8; /* Old browsers */
        background: -moz-linear-gradient(top, #fffef8 0%, #e1edef 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #fffef8 0%, #e1edef 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #fffef8 0%, #e1edef 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffef8', endColorstr='#e1edef', GradientType=0); /* IE6-9 */
        box-sizing: border-box;
        width: 98%;
        float: none;
        margin: 0 auto 50px;
        border: 2px solid #ffb778;
        border-radius: 7px;
        padding: 25px;
        min-height: 800px;
    }

    #sideAds li {
        margin-bottom: 20px;
    }

    #footer {
        background-color: #ff7200;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
    }

    #footer span {
        display: inline-block;
        padding: 10px;
        color: white;
    }

    .cmsText {
        color: #000065;
        margin-bottom: 21px;
        font-size: 17px;
        line-height: 24px;
    }

    /*CALC*/
    #calcDetails {
        background-color: #ff8019;
        padding: 15px;
        margin: 15px auto;
        border-radius: 7px;
        box-sizing: border-box;
    }

    .calcLine {
        margin-bottom: 40px;
    }

    .calcImage {
        float: none;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
    }

    .calcTitle {
        padding-top: 15px;
        font-weight: bold;
        font-size: 20px;
        float: none;
        width: 100%;
        color: #ffffff;
        box-sizing: border-box;
        text-align: center;
    }

    .calcData {
        padding-top: 15px;
        float: none;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
    }

    #calcDetails #year {
        width: 93px;
        border: 2px solid #8f1514;
        border-radius: 5px;
        padding: 5px 0;
        margin-left: 8px;
    }

    #calcDetails #month, #calcDetails #day {
        width: 45px;
        border: 2px solid #8f1514;
        border-radius: 5px;
        padding: 5px 0;
        margin-left: 8px;

    }

    #calcDetails label {
        color: white;
        font-weight: bold;
        margin-right: 5px;
        margin-left: 15px;
        vertical-align: middle;
    }

    #calcSearch {
        text-align: center;
    }

    #calcSearch input {
        color: white;
        background-color: #ff8019;
        border: none;
        padding: 5px 15px;
        font-weight: bold;
        font-size: 25px;
        border-radius: 8px;
    }

    #calcResults #calcResult, #calcResultDate {
        color: #ff0000;
    }

    #calcResults div {
        margin-bottom: 20px;
    }

    #calcResults {
        text-align: center;
        font-weight: bold;
        color: #000269;
        font-size: 19px;
        margin: 15px auto;
    }

    #calcLink {
        text-align: center;
        font-weight: bold;
        color: #ff8019;
        font-size: 23px;
        margin-top: 50px;
    }

    /*END OF CALC*/
    /*CONTACT*/
    #contactPage table {
        width: 100%;
        margin: 25px auto;
    }

    #contactPage .small {
        width: 10%;
        vertical-align: top;
    }

    #contactPage .big {
        width: 91%;
        display: block;
        padding: 10px 0;
    }

    #contactPage .veryBig {
        width: 90%;
        display: block;
        padding: 10px 0;

    }

    #contactPage label {
        display: block;
        background-color: #000269;
        color: white;
        text-align: center;
        width: 54%;
        padding: 10px;
        border-radius: 14px;
        font-weight: bold;
        margin: 10px 0;
        text-align: right;
    }

    #contactPage .veryBig textarea {
        color: #000269;

        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        border: 2px solid #000269;
        height: 250px;
        resize: none;
        font-family: open sans hebrew, sans-serif;
        padding: 5px;
    }

    #contactPage .big input,
    #contactPage .veryBig input {
        color: #000269;
        width: 100%;
        border: 2px solid #000269;
        border-radius: 10px;
        padding: 10px 7px;
        box-sizing: border-box;
    }

    #contactSubmit {
        text-align: center;
    }

    #contactSubmit input {
        color: white;
        background-color: #000269;
        border: none;
        padding: 5px 15px;
        font-weight: bold;
        font-size: 25px;
        border-radius: 8px;
    }

    /*END OF CONTACT*/
    /*CMS*/
    #inPageMenu {
        background-color: #ff8019;
        padding: 10px 5px;
        border-radius: 12px;
        margin-bottom: 15px;
        text-align: center;
        line-height: 26px;
    }

    span.dot {
        height: 7px;
        width: 7px;
        display: inline-block;
        background-color: white;
        vertical-align: middle;
        border-radius: 50%;
        margin: 0 9px 0 2px;
    }

    span.text.current {
        color: #000269;
    }

    span.text {
        color: white;
        font-weight: 600;
        margin-left: 13px;
    }

    #cms p,
    .cms p {
        color: #000269;
        line-height: 15px;
        margin-top: 13px;
    }

    #taamim #cms p,
    #taamim .cms p {
        color: #000269;
        line-height: 26px;
        margin-top: 2px;
    }

    #cms h2,
    .cms h2 {
        background-color: #ff7200;
        width: 90%;
        color: white;
        padding: 10px 23px 10px 10px;
        border-radius: 17px;
        font-weight: bold;
        margin: 10px 0;
    }

    /*END OF CMS*/
    /*ARTICLE*/
    #articles {
        width: 100%;
        box-sizing: border-box;
        margin-top: 15px;
    }

    .article {
        border: 1px solid #ff7200;
        border-radius: 15px;
        background: #fffef8; /* Old browsers */
        background: -moz-linear-gradient(top, #fffef8 0%, #e1edef 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #fffef8 0%, #e1edef 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #fffef8 0%, #e1edef 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffef8', endColorstr='#e1edef', GradientType=0); /* IE6-9 */
        padding: 10px 10px 40px;
        margin-bottom: 30px;
        position: relative;

    }

    .articleReadMore {
        position: absolute;
        left: 10px;
        bottom: 12px;
        text-align: left;
    }

    .articleReadMore a {
        font-weight: bold;
        color: #ff7200;
    }

    .articleData {
        color: #000269;
        float: right;
        width: 80%;
        box-sizing: border-box;

    }

    .articleImage img {
        width: 100%;
    }

    .articleImage {
        text-align: center;
        float: right;
        width: 20%;
        box-sizing: border-box;
    }

    .articleData h2 {
        font-weight: bold;
        margin-bottom: 12px;
    }

    .articleDetails {
        margin-bottom: 5px;
    }

    .articleDetails span {
        font-weight: bold;
    }

    .articleShort iframe {
        width: 96%;
        height: 350px;
        margin: 25px auto;
    }

    #articleCategories {
        float: none;
        width: 92%;
        box-sizing: border-box;
        margin-top: 15px;
    }

    .articleCategories {
        width: 100%;
        background: #ff7200;
        color: white;
        margin-bottom: 12px;
        padding: 10px;
        text-align: center;
        border-radius: 17px;
        font-weight: bold;
    }

    /*END OF ARTICLE*/
    /*TEACHERS*/
    .teacherSort h2 {
        color: #212273;
        font-weight: bold;
        font-size: 148%;
        text-align: center;
    }

    #teacherType, #teacherArea {
        float: none;
        width: 100%;
        text-align: center;
    }

    #teacherSearch {
        float: none;
        width: 100%;
        text-align: center;
    }

    .teacherForm {
        text-align: center;
        background-color: #ff7200;
        color: white;
        padding: 12px;
        font-weight: bold;
        width: 100%;
        margin: 25px auto 12px;
        border-radius: 26px;
        box-sizing: border-box;
    }

    .teacherForm label {
        margin: 0 1px;
    }

    input#tracherSubmit {
        background-color: #ff7200;
        color: white;
        font-weight: bold;
        padding: 13px;
        border-radius: 50px;
        margin-bottom: 29px;
    }

    #teacherTable table {
        width: 100%;
        text-align: center;
    }

    tr.tableOrangeHeader {
        background-color: #ff7200;
        color: white;
        font-weight: bold;
        /* border-radius: 50%; */
        /* border: 1px solid black; */
    }

    tr.tableOrangeHeader th {
        padding: 15px 0;
    }

    tr.tableOrangeHeader th:first-child {
        border-radius: 0 29px 0 0;
    }

    tr.tableOrangeHeader th:last-child {
        border-radius: 29px 0 0 0;
    }

    #teacherTable tbody tr td:first-child {
        border-right: 3px solid #ff7200;
        border-left: 3px solid #ff7200;
    }

    #teacherTable tbody tr td:nth-child(2) {

        border-left: 3px solid #ff7200;
    }

    #teacherTable tbody tr td:last-child {
        border-right: 3px solid #ff7200;
        border-left: 3px solid #ff7200;
    }

    #teacherTable tbody tr td {
        padding: 12px 0;
        color: #212273;
        font-weight: bold;

    }

    #teacherTable tbody tr {
        border-bottom: 1px solid rgba(255, 114, 0, 0.16);
    }

    /*END OF TEACHERS*/
    /*RABINM  */
    .rab video {
        width: 100%;
        box-sizing: border-box;
        margin: 0 0 25px;
    }

    .rabVideo {
        background-color: #ff7200;
        width: 95%;
        margin: 0 auto;
        border-radius: 5px;
        padding: 5px 0 15px;
    }

    .rab {
        margin: 25px 0;
        float: none;
        width: 100%;
    }

    .rabVideoControls {
        text-align: center;
    }

    .rabVideoControls i.fa {
        background-color: white;
        border-radius: 50%;
        color: #900505;
        display: inline-block;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
        height: 35px;
        width: 35px;
        padding-top: 9px;
        box-sizing: border-box;
    }

    .rabTitles h2 {
        font-weight: bold;
    }

    .rabTitles {
        background-color: #ff7200;
        text-align: center;
        color: white;
        border-radius: 15px;
        margin: 0 auto 15px;
        width: 80%;
        padding: 8px;
        box-sizing: border-box;
        font-size: 180%;
    }

    /* END OF RABINM*/
    /*MEMORIALS*/
    .memorials h2 {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .memorials h3 {
        margin-bottom: 10px;
    }

    .memorials {
        height: 100px;
        width: 100%;
        float: none;
        margin: 0 2.5% 20px 0;
        color: white;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 17px;
        background: #ff7200 url("../img/memorials.png") no-repeat left bottom;
    }

    /*END OF MEMORIALS*/
    /*REGISTER*/
    .registerPage {
        float: none;
        width: 100%;
        box-sizing: border-box;
    }

    .registerSubmit {
        text-align: center;
        margin-bottom: 25px;
    }

    .registerForm label {
        background-color: #000269;
        color: white;
        width: 100%;
        display: block;
        text-align: center;
        padding: 3px;
        border-radius: 9px;
        box-sizing: border-box;

    }

    .registerForm input#day,
    .registerForm input#month {
        width: 25%;
        margin-left: 5%;
        display: inline-block;
    }

    .registerForm input#year {
        width: 40%;
        display: inline-block;
    }

    .registerForm input {
        display: block;
        height: 31px;
        padding: 2px 4px;
        box-sizing: border-box;
        border: 2px solid #000269;
        border-radius: 8px;
        width: 100%;
    }

    .registerForm textarea {
        display: block;
        height: 120px;
        padding: 8px;
        box-sizing: border-box;
        border: 2px solid #000269;
        border-radius: 8px;
        width: 100%;
    }

    .registerSubmit input {
        background-color: #000269;
        color: white;
        font-weight: bold;
        padding: 5px 15px;
        border-radius: 8px;
        display: inline-block;
        width: auto;
    }

    .registerForm input {

    }

    td.label.top {
        vertical-align: top;
    }

    td.label {
        width: 30%;
        vertical-align: middle;
        padding: 10px 5px;
    }

    td.input {
        width: 70%;
        vertical-align: middle;
        padding: 10px 5px;
    }

    td.input {
        width: 70%;
        vertical-align: middle;
        padding: 10px 5px;
    }

    .registerForm table {
        width: 90%;
        margin: 15px auto;
    }

    /*END OF REGISTER*/
    /*ONUS*/
    .onus {
        width: 100%;
        box-sizing: border-box;
        background-color: #ff7200;
        margin: 2.5%;
        padding: 5px 10px;
        border-radius: 18px;
    }

    .onus h2 img {
        vertical-align: middle;
        margin-left: 20px;
    }

    .onus h2 {
        color: white;
        font-weight: bold;
        font-size: 134%;
    }

    .onus h3 {
        text-align: center;
        color: white;
        font-weight: bold;
        margin: 15px;
        font-size: 110%;
    }

    .onus h4 {
        text-align: center;
        color: white;
        margin: 10px 0;
        font-size: 111%;
        line-height: 148%;
    }

    /*END OF ONUS*/
    /*LEARN*/
    #learn h2.closed:before {
        transform: rotate(90deg);
        background-position-x: 5px;
    }

    #learn h2:before {
        content: '';
        background: url('../img/h2t.png') no-repeat center left 10px;
        position: absolute;
        left: 5px;
        top: 20%;
        width: 31px;
        height: 25px;
        /* background-color: red; */
        z-index: 9999;
        transition: all 0.5s;
    }

    #learn h2 {
        cursor: pointer;
        position: relative;
        background-color: #ff7200;
        width: 90%;
        color: white;
        padding: 10px 23px 10px 10px;
        border-radius: 17px;
        font-weight: bold;
        margin: 10px 0;
    }

    /*END OF LEARN*/
    /*ASK*/
    input.askSubmit {
        width: 150px;
        margin: 0 auto;
    }

    .answer {
        padding-top: 15px;
        border-bottom: 1px solid #ff8019;
        margin: 15px auto 20px;
    }

    .answerTitle.dark span {
        background-color: #c05600;
    }

    .answerTitle span {
        background-color: #ff7200;
        color: #fff;
        font-weight: bold;
        display: block;
        text-align: center;
        padding: 5px;
        border-radius: 10px;
    }

    .answerTable {
        width: 80%;

    }

    .answerTitle {
        width: 10%;
        box-sizing: border-box;
        padding: 2px 2px 25px 2px;
    }

    .answerContent {
        width: 90%;
        box-sizing: border-box;
        padding: 2px 10px;
    }

    /*END OF ASK*/
    /*PARASHA*/
    #prashaSelector {
        text-align: center;
    }

    #innerChumash span.selected {
        color: #8f1514;
        font-weight: bold;
    }

    #innerChumash span {
        cursor: pointer;
        margin-left: 10px;
        display: inline-block;
        padding: 7px;
    }

    #innerChumash {
        width: 100%;
        background: #ff7200;
        color: white;
        padding: 10px 20px;
        box-sizing: border-box;
        display: inline-block;
        margin: 0 auto 17px;
        border-radius: 10px;
    }

    .mainChumash {
        background: #ff7200;
        color: white;
        font-weight: bold;
        padding: 10px 20px;
        box-sizing: border-box;
        display: inline-block;
        margin: 0 auto 17px;
        border-radius: 10px;
    }

    .mainChumash label {
        vertical-align: middle;
        margin: 0 5px 0 15px;
        display: inline-block;
        margin-bottom: 15px;
    }

    .mainChumash input {
        vertical-align: middle;
        margin: 0 5px 0 2px;
        display: inline-block;
        margin-bottom: 15px;
    }

    #vortText {
        color: #000269;
        margin: 15px auto;
        width: 90%;
        line-height: 24px;
    }

    /*END OF PARASHA*/
    /*STORE*/
    #storeMenu {
        margin-bottom: 25px;
    }

    .storeMenu {
        display: inline-block;
        width: 18%;
        background-color: #ff7200;
        text-align: center;
        color: white;
        box-sizing: border-box;
        padding: 16px;
        font-weight: bold;
        font-size: 25px;
        border-radius: 10px;
        margin-left: 1.4%;
    }

    .store {
        width: 100%;
        float: none;
        box-sizing: border-box;
    }

    .product {
        width: 100%;
        border: 1px solid #ff7200;
        border-radius: 15px;
        background: #fffef8; /* Old browsers */
        background: -moz-linear-gradient(top, #fffef8 0%, #e1edef 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #fffef8 0%, #e1edef 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #fffef8 0%, #e1edef 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffef8', endColorstr='#e1edef', GradientType=0); /* IE6-9 */
        padding: 10px;
        margin-bottom: 30px;
        box-sizing: border-box;
    }

    .product.productBig {
        width: 100%;
    }

    .product.productBig .productContent {
        float: left;
    }

    .productImage {
        float: right;
        width: 29%;
        padding: 11px;
        box-sizing: border-box;
    }

    .productContent {
        float: right;
        width: 71%;
        box-sizing: border-box;
        padding: 11px;
        color: #000065;

    }

    .productTitle {
        font-size: 180%;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .productCategory {
        margin-bottom: 10px;
    }

    .productCategory span {
        font-weight: bold;
    }

    .productExcerpt {
        font-size: 17px;
        line-height: 23px;
    }

    .productLink {
        font-weight: bold;
        text-align: center;
        margin: 13px 0;
        color: #ff7200;
        font-size: 120%;
    }

    .productPrice span {
        color: red;
        font-weight: bold;
    }

    .productPrice {
        text-align: center;
    }

    .storeSideBar {
        float: right;
        width: 20%;
        padding-right: 6%;
        box-sizing: border-box;
    }

    .productImage .productPrice {
        font-size: 100%;
        font-weight: bold;
    }

    /*ALL STORE*/
    /*HOMEPAGE*/
    .homePageFeatured .product .productImage img {
        width: 50%;
    }

    .homePageFeatured .product .productImage,
    .homePageFeatured .product .productContent {
        width: 100%;
    }

    .homePageFeatured .product .productExcerpt {
        font-size: 16px;
        line-height: 21px;
        word-break: break-word;
    }

    #homePageFeatured {
        text-align: center;
    }

    .homePageFeatured {
        display: block;
        width: 95%;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .homePageLink {
        font-weight: bold;
        color: #000065;
        background-color: #ff7200;
        margin: 5px auto;
        border-radius: 10px;
        padding: 5px 0;
    }

    /*END OF HOMEPAGE*/
    /*KRIOT*/
    #kriotSubmit {
        text-align: center;
    }

    .kriotButton {
        font-weight: bold;
        font-size: 150%;
        color: white;
        background-color: #ff7200;
        display: inline-block;
        padding: 10px 21px;
        border-radius: 25px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    #kriah {
        text-align: center;
        margin-top: 30px;
    }

    #kriahDetails {
        display: inline-block;
        background-color: #ff8019;
        color: white;
        padding: 10px;
        margin-top: 10px;
        border-radius: 12px;
    }

    #kriahDetails span {
        margin: 0 10px;
    }

    #kriahSelf {
        position: relative;
        display: inline-block;
        margin: 25px auto;
        background-color: #ff8019;
        width: 100%;
    }

    #kriahMailControllers {
        position: relative;
    }

    #kriahMailControllers .fa {
        cursor: pointer;
        background-color: white;
        padding: 6px;
        border-radius: 50%;
        margin-bottom: 10px;
        color: #921813;
        text-align: center;
        margin-left: 10px;
    }

    #canvasKriaHolder {
        width: 100%;
        height: auto;
        padding: 6px;
        text-align: center;
        box-sizing: border-box;
        position: relative;
    }

    #mobileAdClicker {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    #canvasKriaHolder canvas {
        background-color: white;
        width: 98%;
        box-sizing: border-box;
    }

    #sideKriahControllers .fa {
        background-color: white;
        display: inline-block;
        margin: 0;
        border-radius: 50%;
        padding: 10px;
        color: #8f1514;
        font-weight: bold;
    }

    #sideKriahControllers {
        position: absolute;
        background-color: #ff8019;
        right: 0;
        top: -40px;
        border-radius: 10px 10px 0 0;
        padding: 4px 0;
        left: 0;
        width: 50%;
        margin: 0 auto;
    }

    #kriahControllers {
        position: relative;
    }

    #kriahControllers #sideInKriahControllers {
        margin: 7px auto;
    }

    #sideInKriahControllers i {
        background-color: white;
        height: 22px !important;
        display: inline-block;
        padding: 3px 4px 0;
        vertical-align: middle;
        border-radius: 16px;
    }

    /*END OF KRIOT*/
    .articleShort {
        line-height: 24px;
        word-break: break-word;
    }

    #loader {
        display: none;
        margin-top: 50px;
        text-align: center;
    }

    #loader img {
        width: 60px;
    }

    #vortContent {
        display: none;
    }

    #vortContent #kb {
        text-align: center;
        margin: 25px auto;
    }

    .vort {
        margin-bottom: 50px;
        border-right: 2px solid #ff7200;
        padding-right: 17px;
    }

    #calcHidden, #errorMessage {
        display: none;
    }

    #kriahMemorials {
        text-align: center;
    }

    #kriahMemorials .memorials h4 {
        text-align: center;
        margin-bottom: 10px;
    }

    #kriahMemorials .memorials {
        height: auto;
        margin: 25px 0 0;
        float: none;
        text-align: center;
        display: inline-block;
        width: 100%;
        background-color: #8f1514;
        font-size: 20px;
        min-height: 122px;
    }

    .memorials h4 {
        text-align: center;
    }

    .cms {
        color: #000269;
        line-height: 26px;
        margin-top: 15px;
        word-break: break-word;
    }

    /*TAAMIM*/
    div#taamimChoose {
        width: 80%;
        margin: 20px auto;
        background-color: #ff7200;
        color: white;
        border-radius: 10px;
        padding: 25px;
        box-sizing: border-box;
    }

    .taamimChoose {
        float: none;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        margin-bottom: 25px;
    }

    .taamimChoose h3 {
        font-size: 23px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .taamimGroup {
        height: 22px;
        box-sizing: border-box;
        margin-bottom: 7px;
    }

    .taamimGroup [type="radio"] {
        margin-left: 8px;
    }

    div#taamimGroupChooserImage {
        width: 100%;
        margin: 0 auto;
        position: relative;
        cursor: pointer;
    }

    div#taamimGroupChooserImage img {
        width: 100%;
    }

    .taamimGroupChooserVirtual {
        position: absolute;
        width: 50%;
        height: 50%;
    }

    div#taamimGroupChooserRightTop {
        right: 0;
        top: 0;
    }

    div#taamimGroupChooserLeftTop {
        left: 0;
        top: 0;
    }

    div#taamimGroupChooserRightBottom {
        right: 0;
        bottom: 0;
    }

    div#taamimGroupChooserLeftBottom {
        left: 0;
        bottom: 0;
    }

    div#taamimSelf {
        width: 100%;
        margin: 25px auto;
        border: 2px solid #ff7200;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;
    }

    div#taamimOptions {
        background-color: #ff7200;
        text-align: center;
        padding: 5px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

    }

    .taamimOptions {
        display: inline-block;
        background-color: white;
        padding: 5px 10px;
        border-radius: 13px;
        color: #de4600;
        font-weight: bold;
        cursor: pointer;
    }

    #taamimImage img {
        width: 100%;
    }

    #taamimImage {
        background-color: white;
        height: 79px;
    }

    div#taamimNotFound {
        text-align: center;
        margin: 10px auto;
        display: none;
    }

    #blessWhat {
        width: 100%;
        margin: 10px auto;
        background-color: #ff7200;
        padding: 10px;
        border-radius: 10px;
        box-sizing: border-box;
    }

    .blessWhat {
        width: 100%;
        float: none;
        color: white;
        line-height: 35px;
        font-weight: 500;
    }

    .teacherForm.inBless {
        width: 76%;
        line-height: 29px;
        display: none;
    }

    /*END OF TAAMIM*/
    /*back to top*/
    .cd-top {
        display: inline-block;
        height: 40px;
        width: 40px;
        position: fixed;
        bottom: 40px;
        right: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        /* image replacement properties */
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        background: rgba(255, 114, 0, 0.68) url('../img/cd-top-arrow.svg') no-repeat center 50%;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity .3s 0s, visibility 0s .3s;
        -moz-transition: opacity .3s 0s, visibility 0s .3s;
        transition: opacity .3s 0s, visibility 0s .3s;
    }

    .cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
        -webkit-transition: opacity .3s 0s, visibility 0s 0s;
        -moz-transition: opacity .3s 0s, visibility 0s 0s;
        transition: opacity .3s 0s, visibility 0s 0s;
    }

    .cd-top.cd-is-visible {
        /* the button becomes visible */
        visibility: visible;
        opacity: 1;
    }

    .cd-top.cd-fade-out {
        opacity: .5;
    }

    .no-touch .cd-top:hover {
        background-color: #e86256;
        opacity: 1;
    }

    @media only screen and (min-width: 768px) {
        .cd-top {
            right: 20px;
            bottom: 20px;
        }
    }

    @media only screen and (min-width: 1024px) {
        .cd-top {
            height: 60px;
            width: 60px;
            right: 30px;
            bottom: 30px;
        }
    }

    /* end back to top*/
    #kriahControllers #loader {
        position: absolute;
        top: 0;
        margin-top: 0;
        right: 0;
        left: 0;
        background-color: #ff8019;
        z-index: 555555;
        bottom: 0;
    }

    #kriahControllers #loader img {
        width: 43px;
        vertical-align: middle;
    }

    div#tacherSign {
        display: none;
        position: fixed;
        width: 94%;
        background-color: #f1f6f4;
        border: 2px solid #ff7200;
        top: 56px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 12px;
        border-radius: 10px;
        color: #212273;
        overflow-y: scroll;
        max-height: 87%;
        box-sizing: border-box;
        text-align: center;
    }

    div#tacherSign .x {
        position: absolute;
        left: 5px;
        top: 6px;
        background-color: red;
        color: white;
        padding: 9px 13px;
        border-radius: 50%;
        font-weight: bold;
        cursor: pointer;
    }

    #tacherSign h2 {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
    }

    .tsr {
        float: none;
    }

    .tsr label {
        color: white;
        background-color: #212273;
        width: 26%;
        display: inline-block;
        padding: 8px;
        text-align: center;
        border-radius: 10px;
        margin: 6px;
    }

    .trl {
        float: none;
        width: 100%;
        text-align: center;
    }

    .tsr {
        width: 100%;
    }

    .trl h3 {
        font-weight: bold;
        font-size: 25px;
    }

    #tacherSign h2 {
        margin-bottom: 40px;
    }

    .tsi input {
        width: 62%;
        box-sizing: border-box;
        background-color: white;
        border: 3px solid #212273;
        padding: 6px;
        border-radius: 48px;
        margin-bottom: 15px;
    }

    label[for="t_describe"] {
        color: white;
        background-color: #212273;
        width: 15%;
        display: inline-block;
        padding: 8px;
        text-align: center;
        border-radius: 10px;
        margin: 6px;
    }

    textarea#t_describe {
        border: 3px solid #212273;
        display: inline-block;
        vertical-align: top;
        width: 71%;
        resize: none;
        border-radius: 22px;
        height: 150px;
    }

    div#joinTeacher {
        text-align: center;
    }

    #joinTeacher button img {
        vertical-align: middle;
        margin-left: 10px;
    }

    #joinTeacher button {
        background-color: #000269;
        font-size: 19px;
        color: white;
        border: none;
        padding: 10px 25px;
        margin-bottom: 38px;
        border-radius: 25px;
        cursor: pointer;
    }

    div#tsb {
        text-align: center;
        margin-top: 30px;
    }

    #tsb button {
        background-color: #212273;
        font-size: 25px;
        color: white;
        border: none;
        border-radius: 40px;
        padding: 10px 35px;
        cursor: pointer;
    }

    #innerChumash.ski span {
        display: inline-block;
        width: 30%;
        padding: 11px;
        min-width: 276px;
        box-sizing: border-box;
    }

    .skHolder {
        display: inline-block;
        margin: 10px auto;
    }

    div#headerMainAd {
        display: none;
    }

    .taamimGroup.empty {
        display: none;
    }

    #answers {
        border-top: 1px solid #ff8019;
        padding-top: 10px;
        margin-top: 50px;
    }

    #answers h1, .articleShort h1 {
        margin-right: 0;
    }

    #nextAndPrev {
        text-align: center;
        margin: 15px auto;
        box-sizing: border-box;
    }

    #nextAndPrev a {
        background-color: #ff8019;
        color: white;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 25px 25px 0 0;
        display: inline-block;
    }

    .calcSunSet {
        margin-bottom: 15px;
    }

    .ssKHolder [type='radio'] {
        display: none;
    }

    .inPageMenu {
        display: block;
        margin: 10px auto;
    }

    div#calcNote {
        margin-top: 10px;
        color: red;
        font-size: 15px;
        line-height: 1.6;
    }

    .mobile.ksHelper {
        font-size: 25px;
        font-weight: lighter;
        display: block;
        background-color: rgb(255, 133, 35);
        padding: 10px;
    }

    .mainChumash label.selected {
        color: #8f150a;
    }
}