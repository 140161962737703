@font-face {
    font-family: 'Open Sans Hebrew';
    src: url('fonts/opensanshebrew-light-webfont.eot');
    src: url('fonts/opensanshebrew-light-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/opensanshebrew-light-webfont.woff') format('woff'),
    url('fonts/opensanshebrew-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Hebrew';
    src: url('fonts/opensanshebrew-lightitalic-webfont.eot');
    src: url('fonts/opensanshebrew-lightitalic-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/opensanshebrew-lightitalic-webfont.woff') format('woff'),
    url('fonts/opensanshebrew-lightitalic-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans Hebrew';
    src: url('fonts/opensanshebrew-regular-webfont.eot');
    src: url('fonts/opensanshebrew-regular-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/opensanshebrew-regular-webfont.woff') format('woff'),
    url('fonts/opensanshebrew-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Hebrew';
    src: url('fonts/opensanshebrew-italic-webfont.eot');
    src: url('fonts/opensanshebrew-italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/opensanshebrew-italic-webfont.woff') format('woff'),
    url('fonts/opensanshebrew-italic-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans Hebrew';
    src: url('fonts/opensanshebrew-bold-webfont.eot');
    src: url('fonts/opensanshebrew-bold-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/opensanshebrew-bold-webfont.woff') format('woff'),
    url('fonts/opensanshebrew-bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Hebrew';
    src: url('fonts/opensanshebrew-bolditalic-webfont.eot');
    src: url('fonts/opensanshebrew-bolditalic-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/opensanshebrew-bolditalic-webfont.woff') format('woff'),
    url('fonts/opensanshebrew-bolditalic-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans Hebrew';
    src: url('fonts/opensanshebrew-extrabold-webfont.eot');
    src: url('fonts/opensanshebrew-extrabold-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/opensanshebrew-extrabold-webfont.woff') format('woff'),
    url('fonts/opensanshebrew-extrabold-webfont.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 4, 2014 */

@font-face {
    font-family: 'Open Sans Hebrew';
    src: url('fonts/opensanshebrew-extrabolditalic-webfont.eot');
    src: url('fonts/opensanshebrew-extrabolditalic-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/opensanshebrew-extrabolditalic-webfont.woff') format('woff'),
    url('fonts/opensanshebrew-extrabolditalic-webfont.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, input,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-decoration: none;
    color: inherit;
    font-family: "Open Sans Hebrew", sans-serif;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.clear {
    clear: both;
}

html, body {
    background-color: #fffdf0;
}

.siteBGColor {
    background-color: #ff7200;
}

.siteColor {
    color: #ff7200;
}

.siteBlueColor {
    color: #000269;
}

#blackCome {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.74);
}

#blackCome h1 {
    color: white;
    font-size: 225%;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    top: 32%;
}

#blackCome img {
    position: fixed;
    width: 2%;
    margin: 0 auto;
    text-align: center;
    top: 42%;
    left: 0;
    right: 0;
}

.hidden {
    display: none !important;
}